@font-face {
  font-family: "sf-pro-rounded";
  src: url("../fonts/SF-Pro-Rounded-Medium.otf") format("opentype");
  font-weight: normal;
}

.container {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.logo {
  margin-top: 15%;
}

.main-container {
  display: flex;
  justify-content: center;
}

.desc {
  color: white;
  font-size: 25px;
  font-family: "sf-pro-rounded";
  font-weight: 500;
  width: 80%;
  line-height: 34px;
  margin-top: 5%;
}

.ios-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: black;
  padding: 10px 18px;
  width: fit-content;
  border-radius: 9px;
  box-shadow: 0.5px 0.5px 0.5px 0.5px grey;
  cursor: pointer;
}

.ios-text {
  font-family: "sf-pro-rounded";
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin: 0px;
}

.iphone-container {
  max-width: 100%;
}

.iphone {
  margin-top: 10%;
  margin-left: 6%;
  height: auto;
  width: 70%;
  image-rendering: optimizeQuality;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 1%;
}

.footer p {
  color: white;
  text-align: center;
  font-family: "sf-pro-rounded";
}

#dot {
  margin: 0px 0.5rem;
}

.copyright {
}

@media screen and (min-width: 600px) {
  .logo {
    height: 150px;
    margin-top: 6%;
  }

  .desc {
    font-size: 45px;
    width: 60%;
    line-height: 53px;
    margin-top: 1%;
  }

  .ios-text {
    font-family: "sf-pro-rounded";
    font-size: 24px;
    color: white;
    font-weight: 500;
    margin: 0px;
  }

  .iphone {
    height: 750px;
    width: auto;
    margin-top: 5%;
    margin-left: 2%;

    /* margin-left: 2%; */
  }

  .footer {
    padding: 20px;
  }
  .footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
}
